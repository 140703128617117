import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import Icon from "@components/icon";
import Photo from "@components/photo";
import Phrase from "@components/phrase";
import { useAnimation, m } from "framer-motion";
import { useInView } from "react-intersection-observer";

const DiagramTabs = ({ data = {} }) => {
  const { largerMobileAspectRatio, diagrams } = data;
  const [currentTab, setCurrentTab] = useState(0);
  const [numTabs, setNumTabs] = useState(diagrams.length - 1);
  const labelVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, y: "50px" },
  };
  const labelContainerVariants = {
    visible: {
      opacity: 1,
      transition: { duration: 1 },
      transition: {
        staggerChildren: 0.25,
      },
    },
    hidden: { opacity: 0 },
  };
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView, currentTab]);

  const moveTab = (direction) => {
    if (direction == "next") {
      currentTab = currentTab + 1;
      if (currentTab > numTabs) {
        currentTab = 0;
      }
    } else {
      currentTab = currentTab - 1;
      if (currentTab < 0) {
        currentTab = numTabs;
      }
    }
    setCurrentTab(currentTab);
  };

  let hasPhrase = false;
  diagrams.map((diagram, idx) => {
    if (diagram.phrase) {
      hasPhrase = true;
    }
  });

  return (
    <section
      className={cx("diagram-tabs", "relative", "overflow-hidden")}
      data-theme={diagrams[currentTab]?.backgroundColor?.title.toLowerCase()}
    >
      <div
        className={cx(
          "grid",
          "grid-cols-4",
          "md:grid-cols-20",
          "site-container"
        )}
      >
        <div
          className={cx(
            "col-span-4",
            "md:col-span-18",
            "md:col-start-2",
            "relative"
          )}
        >
          {diagrams.map((diagram, idx) => {
            return (
              <div
                key={idx}
                className={cx(
                  "max-w-full",
                  "mx-auto",
                  "mb-2",
                  "transition-opacity",
                  "duration-[0.5s]",
                  "top-0",
                  "left-0",
                  "w-full",
                  "h-full",
                  "tab-image",
                  {
                    "pt-[100px] sm:pt-[50px]": hasPhrase,
                    "relative opacity-100 z-20": currentTab == idx,
                    "absolute opacity-0 z-10 pointer-events-none":
                      currentTab !== idx,
                  }
                )}
                data-theme={diagrams[
                  currentTab
                ]?.backgroundColor?.title.toLowerCase()}
              >
                {diagram.phrase && diagram.phrase.indexOf("-") === -1 && (
                  <>
                    <div
                      className={cx(
                        "grid",
                        "text-left",
                        "mb-10",
                        "relative",
                        "z-10",
                        "top-10",
                        "left-gutter"
                      )}
                    >
                      <p className={cx("text-lg-1-mobile", "md:text-lg-1")}>
                        {diagram.phrase}
                      </p>
                    </div>
                  </>
                )}
                {diagram.phrase && diagram.phrase.indexOf("-") > -1 && (
                  <>
                    <div
                      className={cx(
                        "text-center",
                        "mb-10",
                        "hidden",
                        "sm:block",
                        "top-0",
                        "absolute",
                        "w-full"
                      )}
                    >
                      <Phrase
                        phrase={diagram.phrase}
                        duration="4"
                        ease="easeInOut"
                        dividerMaxWidth="800px"
                        typeStyle={cx(
                          "text-lg-1-mobile",
                          "md:text-lg-1",
                          "whitespace-nowrap"
                        )}
                      />
                    </div>
                    <div
                      className={cx(
                        "text-center",
                        "mb-10",
                        "block",
                        "sm:hidden",
                        "absolute",
                        "w-full",
                        "top-0"
                      )}
                    >
                      <Phrase
                        phrase={diagram.phrase}
                        vertical={true}
                        dividerMaxWidth="100px"
                        hideLast={true}
                        typeStyle={cx("text-lg-1-mobile", "md:text-lg-1")}
                      />
                    </div>
                  </>
                )}
                <div
                  className={cx(
                    "relative",
                    "diagram",
                    "overflow-hidden",
                    "border-slate/10",
                    `bg-${diagram.backgroundColor?.value}`,
                    {
                      "aspect-[3/4] sm:aspect-[16/12] md:aspect-video":
                        largerMobileAspectRatio,
                      "aspect-[16/12] md:aspect-video":
                        !largerMobileAspectRatio,
                      border: diagram.includeRules == true,
                    }
                  )}
                >
                  <Photo
                    forceLoad={inView}
                    photo={diagram.image}
                    width={2400}
                    sizes="100vw"
                    layout="fill"
                  />

                  {diagram.labels && (
                    <m.div
                      ref={ref}
                      initial="hidden"
                      variants={labelContainerVariants}
                      animate={controls}
                      className={cx(
                        "labels",
                        "absolute",
                        "w-full",
                        "h-full",
                        "top-0",
                        "left-0",
                        "z-20"
                      )}
                    >
                      {diagram.labels.map((label, idx) => (
                        <m.div
                          key={idx}
                          variants={labelVariants}
                          className={cx(
                            "label",
                            "hidden",
                            "md:inline-flex",
                            "absolute",
                            "items-center",
                            "font-sansRegular",
                            {
                              "bg-yellow py-0 px-1 md:px-2 h-[20px]":
                                !label.additionalText,
                            }
                          )}
                          style={{
                            top: label.yOffset,
                            left: label.xOffset,
                          }}
                        >
                          {!label.additionalText && (
                            <span
                              className={cx(
                                "text-xs-mobile",
                                "md:text-xs",
                                "font-sansRegular",
                                "leading-[1]"
                              )}
                            >
                              {label.title}
                            </span>
                          )}
                          {label.additionalText && (
                            <div>
                              <span
                                className={cx(
                                  "text-caption-mobile",
                                  "text-caption",
                                  "font-sansSemiBold",
                                  "tracking-[0.08em]"
                                )}
                              >
                                {label.title}
                              </span>
                              <div
                                className={cx(
                                  "text-[11px]",
                                  "md:text-[13px]",
                                  "font-sansRegular",
                                  "tracking-[0.08em]"
                                )}
                                dangerouslySetInnerHTML={{
                                  __html: label.additionalText.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                            </div>
                          )}
                        </m.div>
                      ))}
                      {diagram.labels.map((label, idx) => (
                        <m.div
                          key={idx}
                          variants={labelVariants}
                          className={cx(
                            "label",
                            "block",
                            "md:hidden",
                            "absolute",
                            "flex",
                            "items-center",
                            "font-sansRegular",
                            {
                              "bg-yellow py-1 px-1 md:px-2":
                                !label.additionalText,
                            }
                          )}
                          style={{
                            top: label.yOffsetMobile
                              ? label.yOffsetMobile
                              : label.yOffset,
                            left: label.xOffsetMobile
                              ? label.xOffsetMobile
                              : label.xOffset,
                          }}
                        >
                          {!label.additionalText && (
                            <span
                              className={cx(
                                "text-xs-mobile",
                                "md:text-xs",
                                "font-sansRegular"
                              )}
                            >
                              {label.title}
                            </span>
                          )}
                          {label.additionalText && (
                            <div>
                              <span
                                className={cx(
                                  "text-caption-mobile",
                                  "text-caption",
                                  "font-sansSemiBold",
                                  "tracking-[0.08em]"
                                )}
                              >
                                {label.title}
                              </span>
                              <div
                                className={cx(
                                  "text-[11px]",
                                  "md:text-[13px]",
                                  "font-sansRegular",
                                  "tracking-[0.08em]",
                                  "leading-[1]"
                                )}
                                dangerouslySetInnerHTML={{
                                  __html: label.additionalText.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                            </div>
                          )}
                        </m.div>
                      ))}
                    </m.div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {diagrams.length > 1 && (
        <div className={cx("grid", "justify-center", "z-20")}>
          <div
            className={cx(
              "flex",
              "flex-row",
              "md:flex-row",
              "bg-white",
              "justify-between",
              "items-center",
              "px-gutter",
              "py-[12px]",
              "rounded-default",
              "relative",
              "mb-[25px]",
              "md:mb-[50px]",
              "z-30",
              "min-w-[250px]"
            )}
          >
            <button
              className="inline-block md:hidden w-[9px] h-[14px]"
              onClick={() => moveTab("prev")}
            >
              <Icon
                name="Caret-Left"
                color="#000000"
                fill="none"
                viewBox="0 0 9 14"
              />
            </button>
            {diagrams.map((diagram, idx) => {
              return (
                <div
                  key={idx}
                  className={cx("mx-4", {
                    block: currentTab == idx,
                    "hidden md:block": currentTab !== idx,
                  })}
                >
                  <button
                    className={cx(
                      "text-subheader-2",
                      "uppercase",
                      "transition-opacity",
                      "font-sansRegular",
                      "relative",
                      {
                        "opacity-30": currentTab !== idx,
                      }
                    )}
                    onClick={() => setCurrentTab(idx)}
                  >
                    {diagram.title}
                    <div
                      className={cx(
                        "border-t",
                        "absolute",
                        "bottom-[-2px]",
                        "left-0",
                        "transition-all",
                        {
                          "w-full": currentTab == idx,
                          "w-0": currentTab !== idx,
                        }
                      )}
                    ></div>
                  </button>
                </div>
              );
            })}
            <button
              className="inline-block md:hidden w-[9px] h-[14px]"
              onClick={() => moveTab("next")}
            >
              <Icon
                name="Caret-Right"
                color="#000000"
                fill="none"
                viewBox="0 0 9 14"
              />
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default DiagramTabs;
